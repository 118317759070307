<template>
  <ConsultaRADA/>
</template>

<script>
import ConsultaRADA from "@/components/rada/consulta/ConsultaRADA"; // @ is an alias to /src
export default {
    components: {
        ConsultaRADA
    }

}
</script>